import React,{ Component } from 'react';
import { Table } from 'react-bootstrap';
import axios from 'axios';
import { API, CLIENT, ASSETS_URL, TONE, MEDIA_TYPE } from '../../_helpers/Constants';
import { Link } from 'react-router-dom';

export default class Report extends Component {

    constructor(props){
        super(props);
        this.state = {
            selectedDateFrom : props.dateFrom,
            selectedDateTo : props.dateTo,
            selectedMedia : props.media || "",
            selectedTopik : props.topik || "",
            selectedSubTopik : props.subtopik || "",
            selectedNarasumber : props.narasumber || "",
            selectedJudul : props.judul || "",
            selectedIsu : props.isu || "",
            selectedNilai : props.nilai || "",
            selectedTipe : props.tipe || "",

            client : JSON.parse(localStorage.getItem('_profile')),
            result : [],

        }
        console.log(this.state);
    }

    componentDidMount(){
        this.getDataResult();
    }

    getDataResult(){
        var self = this;
        var urls =  API + "news/getAll?username="+ localStorage.getItem('_username')
            + "&token="+ localStorage.getItem('_token') 
            + "&subdomain_id=" + CLIENT 
            + "&date_from=" + (this.state.selectedDateFrom !== '' ? this.state.selectedDateFrom : '')
            + "&date_to=" + (this.state.selectedDateTo !== '' ? this.state.selectedDateTo : '')
            + "&limit=999" 
            + "&type=" + (this.state.selectedTipe  !== '' ? this.state.selectedTipe  : '')
            + "&media=" + (this.state.selectedMedia  !== '' ? this.state.selectedMedia  : '')
            + "&speaker=" + (this.state.selectedNarasumber  !== '' ? this.state.selectedNarasumber  : '')
            + "&title=" + (this.state.selectedJudul !== '' ? this.state.selectedJudul : '' )
            + "&subtopic=" + (this.state.selectedSubTopik !== '' ? this.state.selectedSubTopik : '' )
            + "&issue=" + (this.state.selectedIsu !== '' ? this.state.selectedIsu : '' )
            + "&tone=" + (this.state.selectedNilai !== '' ? this.state.selectedNilai : '') 
        console.log(urls);

        axios({
            method: 'get',
            url: urls,
        }).then(function (response) {
            if(response.data.status === 200){
                self.setState({ result : response.data.data});
            }
            self.setState({isLoading : false});
        }).catch(function (error) {
            self.setState({isLoading : false});
        });
    }
    
     
    render(){        
        return (

            <div className='bdz-print-container'>
                <div className='bdz-header-print'>
                    <div className='bdz-header-print-logo'>
                    <img style={{width:65}} src={ASSETS_URL + "img/logo/" + this.state.client.subdomain_logo } alt="" />
                    </div>
                    <div className='bdz-header-print-title'></div>
                </div>
                <Table striped bordered  size="sm" className="table-report" style={{fontSize:10}}>
                    <thead>
                        <tr>
                        <th>No</th>
                        <th>Waktu</th>
                        <th>Judul</th>
                        <th>Media</th>
                        <th>Tipe</th>
                        <th>Ringkasan</th>
                        <th>Narasumber</th>
                        <th>Topik</th>
                        <th>Nilai</th>
                        <th>Link</th>
                        <th>Isu</th>
                        <th>Daerah</th>
                        {/* <th>AD Value</th>
                        <th>PR Value</th>
                        <th>Internal</th> */}
                        </tr>
                    </thead>
                    <tbody>

                    { this.state.result.map((item, i) => {
                                            return  <tr key={item.berita_id}>
                                                        <td>{i+1}</td>
                                                        <td>{item.berita_waktu}</td>
                                                        <td><Link style={{fontSize:10}} to={`/news/${item.berita_id}`}  >{item.berita_judul}</Link></td>
                                                        <td>{item.media_nama}</td>
                                                        <td>{MEDIA_TYPE[item.berita_jenis-1]}</td>
                                                        <td>{item.berita_resume}</td>
                                                        <td>{item.narasumber_nama}</td>
                                                        <td>{item.topik_nama}</td>
                                                        <td>{TONE[parseInt(item.berita_tone)+1]}</td>
                                                        <td>
                                                            {
                                                                item.berita_jenis == 1 ? 
                                                                <a style={{fontSize:10}} href={item.berita_link_org} target="_blank">Link</a>
                                                                :null
                                                            }
                                                        </td>
                                                        <td>{item.berita_isu}</td>
                                                        <td>{item.daerah_nama}</td>
                                                        {/* <td>{item.berita_ar}</td>
                                                        <td>{item.berita_pr}</td>
                                                        <td>{item.berita_internal}</td> */}
                                                    </tr>
                                        })
                                    } 

                    </tbody>
                </Table>
                             
            </div>
                    
        ) 
    }
  
}

