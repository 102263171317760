import React,{ Component } from 'react';
import { DateHelper } from '../../_helpers/DateHelper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';
import { API, CLIENT, ASSETS_URL } from '../../_helpers/Constants';

import { Doughnut } from 'react-chartjs-2';
export default class Graphtone extends Component {

    constructor(props){
        super(props);
        this.state = {
            id : '',
            data : [],
            dateFrom : props.dateFrom || DateHelper(),
            dateTo : props.dateTo || DateHelper(),
            title : props.title ,
            topic: '',
            tone : '',


            width : props.width || 300,
            height : props.height || 300,

            labels : [],
            values : [],
            colors : [],
        }
    }

    componentDidMount(){
      this.getDataResult();
    }
    
    forceUpdateComponent(dateFrom, dateTo, topic, tone){
      this.setState({
          dateFrom : dateFrom,
          dateTo : dateTo,
          topic : topic,
          tone : tone,
        }, () => {
          this.getDataResult();
        })
    }

    
    getDataResult(){
      this.setState({isLoading : true});
      var self = this;
      var urls =  API + "analysis/getcharttone?username="+ localStorage.getItem('_username')
          + "&token="+ localStorage.getItem('_token') 
          + "&subdomain_id=" + CLIENT 
          + "&date_from=" + this.state.dateFrom
          + "&date_to=" + this.state.dateTo
          + "&topic=" + this.state.topic
          + "&tone=" + this.state.tone
      // console.log('bodhik');
      // console.log(urls);
      axios({
          method: 'get',
          url: urls,
      }).then(function (response) {
          if(response.data.status == 200){
              // self.setState({ result : response.data.data});
              let labelArr = [];    
              let colorArr = [];
              response.data.data.map((item, i) => {
                labelArr.push(item.berita_tone == 0 ? 'Netral' : item.berita_tone == '-1' ? 'Negatif' : 'Positif');
                colorArr.push(item.berita_tone == 0 ? 'gray' : item.berita_tone == '-1' ? 'red' : 'green');
              });
              
              let valueArr = [];     
              response.data.data.map((item, i) => {
                valueArr.push(item.total);
              })
              self.setState({ labels : labelArr, values : valueArr, colors : colorArr});
          }
          self.setState({isLoading : false});
      }).catch(function (error) {
          self.setState({isLoading : false});
      });
  }



    render(){   
      

        const data = {
            labels: this.state.labels,
            datasets: [{
                data   : this.state.values,
                backgroundColor: this.state.colors,
                // hoverBackgroundColor: ['#02991a',  '#c00000','#838383', '#02991a']
            }]
          }; 

          const options = {
            maintainAspectRatio: true,
            responsive: true,
            title:{
                display:true,
                text:this.state.title,
                fontSize:20
              },
            legend: {
              position: 'left',
              labels: {
                boxWidth: 10
              }
            }
          }


        return (
            <div className='bdz-graph-container'>
                <Doughnut ref="chart" width={this.props.width} height={this.props.height} data={data} options={options} />  
                {this.state.isLoading ? <FontAwesomeIcon style={{marginRight:5,alignSelf:"center"}} icon={faSpinner} spin/> : '' }
            </div>
        ) 
    }
  
}

