
import React,{ Component } from 'react';
import { DateHelper } from '../../_helpers/DateHelper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';
import { API, CLIENT, ASSETS_URL } from '../../_helpers/Constants';

import { HorizontalBar } from 'react-chartjs-2';
export default class Graphtonerubrik extends Component {

    constructor(props){
        super(props);
        this.state = {
            id : '',
            data : [],
            dateFrom : props.dateFrom || DateHelper(),
            dateTo : props.dateTo || DateHelper(),
            title : props.title,
            topic: '',
            tone : '',

            
            width : props.width || 300,
            height : props.height || 300,

            labels : [],
            valuesPositif : [],
            valuesNegatif : [],
            valuesNetral : [],

        }
    }

    componentDidMount(){
      this.getDataResult();
    }
    
    forceUpdateComponent(dateFrom, dateTo, topic, tone){
      this.setState({
          dateFrom : dateFrom,
          dateTo : dateTo,
          topic : topic,
          tone : tone,
        }, () => {
          this.getDataResult();
        })
    }

    
    getDataResult(){
      this.setState({isLoading : true});
      var self = this;
      var urls =  API + "analysis/getchartonerubrication?username="+ localStorage.getItem('_username')
          + "&token="+ localStorage.getItem('_token') 
          + "&subdomain_id=" + CLIENT 
          + "&date_from=" + this.state.dateFrom
          + "&date_to=" + this.state.dateTo
          + "&topic=" + this.state.topic
          + "&tone=" + this.state.tone
          + "&limit=5"
      console.log(urls);
      axios({
          method: 'get',
          url: urls,
      }).then(function (response) {
          if(response.data.status == 200){
              let labelArr = [];    

              response.data.data.map((item, i) => {
                labelArr.push(item.rubrikasi_nama);
              });
              
              let valueArrPositif = [];     
              let valueArrNegatif = [];     
              let valueArrNetral = [];     
              response.data.data.map((item, i) => {
                 item.tone.map((itemTone, index) => {
                  if (itemTone.berita_tone == 1) 
                    valueArrPositif.push(itemTone.total);
                  
                  if (itemTone.berita_tone == -1) 
                    valueArrNegatif.push(itemTone.total);   
                  
                  if (itemTone.berita_tone == 0) 
                  valueArrNetral.push(itemTone.total); 
                 })
              })
              self.setState({ labels : labelArr, valuesPositif : valueArrPositif, valuesNetral : valueArrNetral, valuesNegatif : valueArrNegatif});
          }
          self.setState({isLoading : false});
      }).catch(function (error) {
          self.setState({isLoading : false});
      });
  }



    render(){   
      

        const data = {
            labels: this.state.labels,
            datasets: [{
              data   : this.state.valuesPositif,
              label: 'Positif',
              backgroundColor: '#28a7456b',
              borderWidth: 1,
          },{
            data   : this.state.valuesNetral,
            label: 'Netral',
            backgroundColor: '#aaaaaa80',
            borderWidth: 1,
        },{
          data   : this.state.valuesNegatif,
          label: 'Negatif',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderWidth: 1,
      }
        ]

          }; 

          const options = {
            maintainAspectRatio: true,
            responsive: true,
            title:{
                display:true,
                text:this.state.title,
                fontSize:20
              },
            legend: {
              position: 'bottom',
              labels: {
                boxWidth: 10
              }
            },
            scales: {
              xAxes: [{ stacked: true }],
              yAxes: [{ stacked: true }]
            }
          }


        return (
            <div className='bdz-graph-container'>
                <HorizontalBar ref="chart"  width={this.props.width} height={this.props.height}  data={data} options={options} />  
                {this.state.isLoading ? <FontAwesomeIcon style={{marginRight:5,alignSelf:"center"}} icon={faSpinner} spin/> : '' }
            </div>
        ) 
    }
  
}

