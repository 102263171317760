import React,{ Component } from 'react';
import { DateHelper } from '../../_helpers/DateHelper';

import { Table, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner,faPrint } from '@fortawesome/free-solid-svg-icons';

import ReactToPrint from 'react-to-print';

import axios from 'axios';
import { API, CLIENT, MEDIA_TYPE, TONE} from '../../_helpers/Constants';

import  Header from '../../_components/Header';
import  Footer from '../../_components/Footer';
import  Graphtone from '../Quantitative/Graphtone';
import  Graphtonemedia from '../Quantitative/Graphtonemedia';
import Graphtopissue from '../Quantitative/Graphtopissue';
import Graphmediaanalysis from '../Quantitative/Graphmediaanalysis';
import Graphtopicanalysis from '../Quantitative/Graphtopicanalysis';
import Graphtonetopic from '../Quantitative/Graphtonetopic';
import Graphtonenarasumber from '../Quantitative/Graphtonenarasumber';
import Graphtonewartawan from '../Quantitative/Graphtonewartawan';
import Graphtonejenis from '../Quantitative/Graphtonejenis';
import Graphtonelembaga from '../Quantitative/Graphtonelembaga';
import Graphtonerubrik from '../Quantitative/Graphtonerubrik';

export default class Analysis extends Component {

    constructor(props){
        super(props);
        this.state = {
            id : '',
            data : [],
            dateFrom : DateHelper(),
            dateTo : DateHelper(),
            onlineNews : "",


            media : [],
            topik : [],
            subtopik : [],
            lembaga : [],
            narasumber : [],
            isLoading : false,

            selectedDateFrom : DateHelper(),
            selectedDateTo : DateHelper(),
            selectedTopik : "",
            selectedNilai : "",

        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        
        this.forceUpdateTone = React.createRef();
        this.forceUpdateToneMedia = React.createRef();
        this.forceUpdateToneTopik = React.createRef();
        this.forceUpdateToneNarasumber = React.createRef();
        this.forceUpdateToneWartawan = React.createRef();
        this.forceUpdateToneJenis = React.createRef();
        this.forceUpdateToneLembaga = React.createRef();
        this.forceUpdateToneRubrik = React.createRef();
        this.forceUpdateTopIsu = React.createRef();
        this.forceUpdateAnalisaMedia = React.createRef();
        this.forceUpdateAnalisaTopik = React.createRef();
    }

    componentDidMount (){
        
        this.getDataTopik();
    }
    getDataTopik(){
        var self = this;
        var urls =  API + "masterdata/gettopik?username="+ localStorage.getItem('_username')
            + "&token="+ localStorage.getItem('_token') 
            + "&subdomain_id=" + CLIENT ;
        axios({
            method: 'get',
            url: urls,
        }).then(function (response) {
            if(response.data.status === 200){
                self.setState({ topik : response.data.data});
            }
        }).catch(function (error) {
                
        });
    }
    handleChange(event){
        this.setState({
            [event.target.name] : event.target.value
        });
    }

    handleSubmit(event){
        if(this.state.selectedDateFrom === '' || this.state.selectedDateTo === '' )
            return   
            
        this.forceUpdateTone.current.forceUpdateComponent(this.state.selectedDateFrom, this.state.selectedDateFrom, this.state.selectedTopik, this.state.selectedNilai);
        this.forceUpdateToneMedia.current.forceUpdateComponent(this.state.selectedDateFrom, this.state.selectedDateFrom, this.state.selectedTopik, this.state.selectedNilai);
        this.forceUpdateTopIsu.current.forceUpdateComponent(this.state.selectedDateFrom, this.state.selectedDateFrom, this.state.selectedTopik, this.state.selectedNilai);
        this.forceUpdateAnalisaMedia.current.forceUpdateComponent(this.state.selectedDateFrom, this.state.selectedDateFrom, this.state.selectedTopik, this.state.selectedNilai);
        this.forceUpdateAnalisaTopik.current.forceUpdateComponent(this.state.selectedDateFrom, this.state.selectedDateFrom, this.state.selectedTopik, this.state.selectedNilai);
        this.forceUpdateToneTopik.current.forceUpdateComponent(this.state.selectedDateFrom, this.state.selectedDateFrom, this.state.selectedTopik, this.state.selectedNilai);
        this.forceUpdateToneNarasumber.current.forceUpdateComponent(this.state.selectedDateFrom, this.state.selectedDateFrom, this.state.selectedTopik, this.state.selectedNilai);
        this.forceUpdateToneWartawan.current.forceUpdateComponent(this.state.selectedDateFrom, this.state.selectedDateFrom, this.state.selectedTopik, this.state.selectedNilai);
        this.forceUpdateToneJenis.current.forceUpdateComponent(this.state.selectedDateFrom, this.state.selectedDateFrom, this.state.selectedTopik, this.state.selectedNilai);
        this.forceUpdateToneLembaga.current.forceUpdateComponent(this.state.selectedDateFrom, this.state.selectedDateFrom, this.state.selectedTopik, this.state.selectedNilai);
        this.forceUpdateToneRubrik.current.forceUpdateComponent(this.state.selectedDateFrom, this.state.selectedDateFrom, this.state.selectedTopik, this.state.selectedNilai);
        
    }

    
     
    render(){        

        return (

            <div>
                <Header></Header>

                <br></br>
                    <div className="blog-area section-padding-0-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="contact-title" style={{float:'left'}}>
                                    <h2>Laporan Analysis</h2>
                                </div>
                                <div style={{width:500}}  className="float-right hide-print report-control">

                                        <Form.Group as={Row} controlId="formHorizontalEmail">
                                            <Form.Label column sm={3}>
                                                Date
                                            </Form.Label>
                                            <Col sm={9}>
                                                <Row>
                                                    <Col>
                                                        <Form.Control size="sm"  type="date" className="form-control sm my-1 mr-sm-2" name="selectedDateFrom" onChange={this.handleChange } value={this.state.selectedDateFrom} placeholder="Date From" />
                                                    </Col>
                                                    <Col>
                                                        <Form.Control size="sm"  type="date" className="form-control sm my-1 mr-sm-2" name='selectedDateTo' onChange={this.handleChange } value={this.state.selectedDateTo} placeholder="Date to" />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Form.Group>

                                    
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={3}>
                                        Tema
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Form.Control name='selectedTopik' onChange={this.handleChange } value={this.state.selectedTopik} size="sm" as="select">
                                        <option value=""></option>
                                            
                                            { this.state.topik.map((item, i) => {
                                                    return <option value={item.topik_id}>{item.topik_nama}</option>
                                                })
                                            }  
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={3}>
                                        Nilai Berita
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Form.Control  size="sm" as="select"  onChange={this.handleChange }  name='selectedNilai' value={this.state.selectedNilai}>
                                            <option value=""></option>
                                            <option value="-1">Negative</option>
                                            <option value="0">Netral</option>
                                            <option value="1">Positif</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                                    
                                <button type="submit" onClick={this.handleSubmit} className="btn float-right  btn-success my-1 mr-sm-2">
                                        {this.state.isLoading ? <FontAwesomeIcon style={{marginRight:5}} icon={faSpinner} spin/> : '' }
                                        Submit
                                    </button>
                                    <button type="reset" onClick={this.handleReset} className="btn float-right  btn-warning my-1 mr-sm-2">Reset</button>
                                    {/* <button onClick={this.handlePrint} className="btn float-right  btn-warning my-1 mr-sm-2" to='/printreport' params={{ testvalue: "hello" }} >
                                        <FontAwesomeIcon style={{marginRight:5}} icon={faPrint}/>
                                    </button> */}

                                    <ReactToPrint
                                        trigger={() => <a href="#" className="btn float-right  btn-info my-1 mr-sm-2">
                                        <FontAwesomeIcon style={{marginRight:5}} icon={faPrint}/>
                                        </a>}
                                        content={() => this.componentRef}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className='clearfix'></div>
                        <br></br>
                        <div className="row">
                                <div className='col-12 col-md-6 graph-container'>
                                    <Graphtone title="Grafik Tone Berita" width="300" height="200" dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} ref={this.forceUpdateTone} />
                                </div>
                                <div className='col-12 col-md-6 graph-container'>
                                    <Graphtonemedia title="Grafik Tone Media" width="300" height="200" dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} ref={this.forceUpdateToneMedia} />
                                </div>

                                <div className='col-12 col-md-6 graph-container'>
                                    <Graphtonetopic title="Grafik Tone Topik" width="300" height="200" dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} ref={this.forceUpdateToneTopik} />
                                </div>

                                <div className='col-12 col-md-6 graph-container'>
                                    <Graphtonenarasumber title="Grafik Tone Narasumber" width="300" height="200" dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} ref={this.forceUpdateToneNarasumber}/>
                                </div>

                                <div className='col-12 col-md-6 graph-container'>
                                    <Graphtonewartawan title="Grafik Tone Wartawan" width="300" height="200" dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} ref={this.forceUpdateToneWartawan} />
                                </div>

                                <div className='col-12 col-md-6 graph-container'>
                                    <Graphtonejenis title="Grafik Tone Jenis" width="300" height="200" dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} ref={this.forceUpdateToneJenis} />
                                </div>

                                <div className='col-12 col-md-6 graph-container'>
                                    <Graphtonelembaga title="Grafik Tone Lembaga" width="300" height="200" dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} ref={this.forceUpdateToneLembaga} />
                                </div>

                                <div className='col-12 col-md-6 graph-container'>
                                    <Graphtonerubrik title="Grafik Tone Rubrik" width="300" height="200" dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} ref={this.forceUpdateToneRubrik} />
                                </div>  

                                <div className='col-12 col-md-6 graph-container'>
                                    <Graphtopissue title="Grafik Top 5 Isu" width="300" height="200" dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} ref={this.forceUpdateTopIsu}/>
                                </div>
                                <div className='clearfix'></div>
                                <br></br>
                                <div className='col-12 col-md-6 graph-container'>
                                    <Graphmediaanalysis title="Grafik Analisa Media" width="300" height="200" dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} ref={this.forceUpdateAnalisaMedia} />
                                </div>  

                                <div className='col-12 col-md-6 graph-container'>
                                    <Graphtopicanalysis title="Grafik Analisa Topik" width="300" height="200" dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} ref={this.forceUpdateAnalisaTopik} />
                                </div>


                               
                            
                        </div>
                    </div>
                </div>


                <Footer></Footer>
            </div>
        ) 
    }
  
}

