import React,{ Component } from 'react';
import './Home.css';

import  Header from '../../_components/Header';
import  Footer from '../../_components/Footer';
import  Vertical_1 from '../../_components/Vertical_1';
import  Horizontal_1 from '../../_components/Horizontal_1';
import  Slider_1 from '../../_components/Slider_1';
import  Ticker_1 from '../../_components/Ticker_1';

import { DateHelper } from '../../_helpers/DateHelper';
import  Graphtone from '../Quantitative/Graphtone';
import Graphtopissue from '../Quantitative/Graphtopissue';

// image //
import Image1 from '../../_assets/img/bg-img/16.jpg';
import TwitterContainer from '../../_components/TwitterContainer';


export default class Home extends Component {
    constructor(props){
        super(props);
        this.state = {
            
            dateFrom : '2019-01-01',
            dateTo : DateHelper(),
            client : JSON.parse(localStorage.getItem('_profile'))
        }
        
    }

    componentDidMount() {      
        // console.log(datasets[0].data);
      }
    
     
    render(){        

        return (

                <div>
                    <Header></Header>
                    
                    <div className="hero-area">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-12 col-lg-12">
                                    <Ticker_1 total="5"></Ticker_1>
                                </div>

                            </div>
                        </div>
                    </div>


                            <div className="featured-post-area">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-5">
                                            <div className='bdz-header-title'  style={{backgroundColor:this.state.client.subdomain_color}}> 
                                                Berita Utama
                                            </div>
                                            <Slider_1 total="10"></Slider_1>    

                                        </div>

                                        <div className="col-12 col-md-7 col-lg-7">
                                            <div className='row'>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                    
                                                <div className="col-12 col-lg-12">
                                                    <div className='bdz-header-title'  style={{backgroundColor:this.state.client.subdomain_color}}> 
                                                        Berita Cetak
                                                    </div>
                                                    <Vertical_1 total="3" showBig1="true" type="printed" category=""></Vertical_1>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className='col-12 col-md-12 col-lg-12'>
                                                    <div className='bdz-header-title' style={{backgroundColor:this.state.client.subdomain_color}}> 
                                                        Berita Online
                                                    </div>
                                                    <Vertical_1 total="3" showBig1="true" type="online" category=""></Vertical_1>                                                    
                                                </div>

                                            </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

							{/* BY TOPIC */}
                            <Horizontal_1 total="4" type="onlineprinted" categoryID="1872" category="Political Briefing"></Horizontal_1>
							<Horizontal_1 total="4" type="onlineprinted" categoryID="1873" category="Policy Issues"></Horizontal_1>
							<Horizontal_1 total="4" type="onlineprinted" categoryID="1874" category="Protelindo & Related News"></Horizontal_1>
							<Horizontal_1 total="4" type="onlineprinted" categoryID="1875" category="Competitors - Related News"></Horizontal_1>
							<Horizontal_1 total="4" type="onlineprinted" categoryID="1876" category="Providers"></Horizontal_1>

                            <div className="container">
                                <br></br>
                                <hr></hr>
                                <div className='row'>
                                    <div className="col-12 col-md-6 col-lg-6">
                                            
                                        <div className="col-12 col-lg-12">
                                            <Graphtone title="Grafik Tone Berita" height={250} dateFrom={this.state.dateFrom} dateTo={this.state.dateTo}/>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className='col-12 col-md-12 col-lg-12'>
                                            <Graphtopissue title="Grafik Top Isu" height={250} dateFrom={this.state.dateFrom} dateTo={this.state.dateTo}/>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            {/* BY TOPIC */}
                            

                            {/* TV NEWS */}

                            
                            <div className="video-post-area bg-img bg-overlay" style={{backgroundImage:"url("+Image1+")"}}>
                                
                                <div className="container">
                                    <Horizontal_1 total="4" type="tv" category="Berita TV" titleColor="#fff"></Horizontal_1>                                                    
                                    
                                </div>
                            </div>

                    <Footer></Footer>
                            
                </div>
            
        
    
        ) 
    }
  
}

