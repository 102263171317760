import React,{ Component } from 'react';
import { DateHelper } from '../../_helpers/DateHelper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner,faFilePdf  } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';
import { API, CLIENT, ASSETS_URL } from '../../_helpers/Constants';

import  Header from '../../_components/Header';
import  Footer from '../../_components/Footer';

export default class Kliping extends Component {

    constructor(props){
        super(props);
        this.state = {
            id : '',
            result : [],
            type : 1,
            isLoading : false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }


    componentDidMount(){
        this.getDataResult();
    }
    componentWillReceiveProps(nextProps){
        this.getDataResult();
    }

    handleChange(event){
        this.setState({
            [event.target.name] : event.target.value
        });
    }

    handleSubmit(event){
        this.getDataResult();
    }

    getDataResult(){
        this.setState({isLoading : true});
        var self = this;
        var urls =  API + "kliping/getKliping?username="+ localStorage.getItem('_username')
            + "&token="+ localStorage.getItem('_token') 
            + "&subdomain_id=" + CLIENT 
            + "&limit=99" 
            + "&type=" 
        console.log(urls);
        axios({
            method: 'get',
            url: urls,
        }).then(function (response) {
            if(response.data.status  ===  200){
                console.log(response.data.data);
                self.setState({ result : response.data.data});
            }
            self.setState({isLoading : false});
        }).catch(function (error) {
            self.setState({isLoading : false});
        });
    }

    
     
    render(){        
        return (

            <div>
                <Header></Header>

                <br></br>
                    <div className="blog-area section-padding-0-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="contact-title" style={{float:'left'}}>
                                    <h2>Kliping Berita</h2>
                                </div>

                            </div>
                        </div>
                        <div className='clearfix'></div>
                        <br></br>
                        <div className="row">
                            <div className='col-12 col-md-12'>
                            {this.state.isLoading ? <FontAwesomeIcon style={{marginRight:5}} icon={faSpinner} spin/> : '' }
                                
                            <div className='bdz-list-recomendation'>
                            { this.state.result.map((item, i) => {
                                          return <div className='bdz-list-kliping-item'>
                                                    <a href={ASSETS_URL + '/pdf/' + item.kliping_file} target="_blank">
                                                    <FontAwesomeIcon size={'5x'} style={{color:"#aaa"}} icon={faFilePdf} /> 
                                                    <br></br>
                                                    <div style={{padding:20}}>
                                                        {item.kliping_judul}
                                                    </div>
                                                    </a>
                                                </div>          
                                        })
                                    } 
                            </div>
                            </div>
                            
                        </div>
                    </div>
                </div>


                <Footer></Footer>
            </div>
        ) 
    }
  
}

