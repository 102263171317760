import React,{ Component } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import  Vertical_1 from '../../_components/Vertical_1';
import { DateHelper } from '../../_helpers/DateHelper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import  Header from '../../_components/Header';
import  Footer from '../../_components/Footer';

export default class Search extends Component {

    constructor(props){
        super(props);
        this.state = {
            id : '',
            data : [],
            activeTab : props.activeTab || 1,
            dateFrom : '2019-01-01',
            dateTo : DateHelper(),
            onlineNews : "",
            keyword : '',
        }
        this.handleSelect = this.handleSelect.bind(this);

        //call child function with ref
        this.printRef = React.createRef();
        this.onlineRef = React.createRef();
        this.tvRef = React.createRef();
        this.radioRef = React.createRef();
    }

    componentWillMount() {
        this.setState({keyword : this.props.match.params.keyword });
    }

    componentWillReceiveProps(nextProps){
        var self = this;
        this.setState({keyword : nextProps.match.params.keyword },
        ()=>{
            self.onlineRef.current.forceUpdateComponent(self.state.dateFrom, self.state.dateTo, self.state.keyword );
            self.printRef.current.forceUpdateComponent(self.state.dateFrom, self.state.dateTo, self.state.keyword );
            self.tvRef.current.forceUpdateComponent(self.state.dateFrom, self.state.dateTo, self.state.keyword );
        });
    }



    handleSelect(selectedTab) {
        this.setState({
          activeTab: selectedTab
        });
    }

    render(){        
        return (

            <div>
                <Header></Header>

                <br></br>
                    <div className="blog-area section-padding-0-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="contact-title" style={{float:'left'}}>
                                    <h2>Search | <small>{this.state.keyword}</small> </h2>
                                </div>

                            </div>
                        </div>
                        <div className='clearfix'></div>
                        <br></br>
                        <div className="row">
                            <div className='col-12 col-md-12'>
                               
                                <Tabs activeKey={this.state.activeTab} onSelect={this.handleSelect}>
                                    <Tab eventKey={1} title="Berita Online ">
                                        <br></br>
                                        <Vertical_1 total="99" showBig1="false" keyword={this.state.keyword} type="online" category="" dateFrom={this.state.dateFrom}  dateTo={this.state.dateTo} ref={this.onlineRef} ></Vertical_1>
                                    </Tab>
                                    <Tab eventKey={2} title="Berita Cetak">
                                        <br></br>
                                        <Vertical_1 total="99" showBig1="false" keyword={this.state.keyword} type="printed" category="" dateFrom={this.state.dateFrom}  dateTo={this.state.dateTo} ref={this.printRef}></Vertical_1>
                                    </Tab>
                                    <Tab eventKey={3} title="Berita TV">
                                        <br></br>
                                        <Vertical_1 total="99" showBig1="false" keyword={this.state.keyword} type="tv" category="" dateFrom={this.state.dateFrom}  dateTo={this.state.dateTo} ref={this.tvRef}></Vertical_1>
                                    </Tab>
                                </Tabs>

                            </div>
                            
                        </div>
                    </div>
                </div>


                <Footer></Footer>
            </div>
        ) 
    }
  
}

