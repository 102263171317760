import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';

export default class Login extends Component {
  constructor(props){
    super(props);
    this.state = { }
  }

  componentDidMount(){
    localStorage.removeItem("_token",);
    localStorage.removeItem("_username");
    localStorage.removeItem("_profile");
  }
 
  render(){    
    return (
        <Redirect to="/"></Redirect>
    )
  }
 
}

