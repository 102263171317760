import React,{ Component } from 'react';
import { DateHelper } from '../../_helpers/DateHelper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';
import { API, CLIENT, ASSETS_URL } from '../../_helpers/Constants';

import { Pie } from 'react-chartjs-2';
export default class Graphmediaanalysis extends Component {

    constructor(props){
        super(props);
        this.state = {
            id : '',
            data : [],
            dateFrom : props.dateFrom || DateHelper(),
            dateTo : props.dateTo || DateHelper(),

            topic: '',
            tone : '',

            title : props.title ,

            width : props.width || 300,
            height : props.height || 300,

            labels : [],
            values : [],
        }
    }

    componentDidMount(){
      this.getDataResult();
    }
    
    forceUpdateComponent(dateFrom, dateTo, topic, tone){
      this.setState({
          dateFrom : dateFrom,
          dateTo : dateTo,
          topic : topic, 
          tone : tone,
        }, () => {
          this.getDataResult();
        })
    }

    
    getDataResult(){
      this.setState({isLoading : true});
      var self = this;
      var urls =  API + "analysis/getchartmedia?username="+ localStorage.getItem('_username')
          + "&token="+ localStorage.getItem('_token') 
          + "&subdomain_id=" + CLIENT 
          + "&date_from=" + this.state.dateFrom
          + "&date_to=" + this.state.dateTo
          + "&topic=" + this.state.topic
          + "&tone=" + this.state.tone
          + "&limit=10"
      console.log(urls);
      axios({
          method: 'get',
          url: urls,
      }).then(function (response) {
          if(response.data.status == 200){
              // self.setState({ result : response.data.data});
              let labelArr = [];    
              response.data.data.map((item, i) => {
                labelArr.push(item.media_nama);
              });
              
              let valueArr = [];     
              response.data.data.map((item, i) => {
                valueArr.push(item.total);
              })
              self.setState({ labels : labelArr, values : valueArr});
          }
          self.setState({isLoading : false});
      }).catch(function (error) {
          self.setState({isLoading : false});
      });
  }



    render(){   
      

        const data = {
            labels: this.state.labels,
            datasets: [{
                data   : this.state.values,
                backgroundColor: ['#1d00fd', '#1f09c8', '#3a2ab3', '#5f53bf', '#3320d0', '#2a2265', '#4a3ea1', '#3421bd', '#1f0ca7', '#11046d'],
            }]
          }; 

          const options = {
            maintainAspectRatio: true,
            responsive: true,
            title:{
                display:true,
                text:this.state.title,
                fontSize:20
              },
            legend: {
              position: 'right',
              labels: {
                boxWidth: 10
              }
            }
          }


        return (
            <div className='bdz-graph-container'>
                <Pie ref="chart"  width={this.props.width} height={this.props.height} data={data} options={options} />  
                {this.state.isLoading ? <FontAwesomeIcon style={{marginRight:5,alignSelf:"center"}} icon={faSpinner} spin/> : '' }
            </div>
        ) 
    }
  
}

