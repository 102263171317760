import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
import checkTokenServer from '../_helpers/TokenHelper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faList, faCheck, faChartLine, faFilePdf,faSignOutAlt, faBars, faChalkboard, faChartPie, faCommentAlt  } from '@fortawesome/free-solid-svg-icons';
import SlideToggle from "react-slide-toggle";


import axios from 'axios';
import { API, CLIENT, MEDIA_TYPE, TONE} from '../_helpers/Constants';

import { ASSETS_URL } from '../_helpers/Constants';


import Autocomplete from "./Autocomplete";

export default class Header extends Component {

    constructor(props){
        super(props);
        this.state = {
            isLoggedIn : true,
            client : JSON.parse(localStorage.getItem("_profile")),
            keyword : "",
            toSearch : false,
            toggleEvent: Date.now(),
            city : []
        }

		this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

       

    }

    getDataCity(){
        var self = this;
        var urls =  API + "masterdata/getcityprop?username="+ localStorage.getItem('_username')
            + "&token="+ localStorage.getItem('_token') 
            + "&subdomain_id=" + CLIENT ;
        axios({
            method: 'get',
            url: urls,
        }).then(function (response) {
            if(response.data.status === 200){
                var index = 0;
                var cityx = [];
                response.data.data.forEach(data => {
                    cityx[index] = data.daerah;
                    index++;
                });
                self.setState({ city : cityx});
            }
        }).catch(function (error) {
                
        });
    }
    onToggle = () => {
        this.setState({ toggleEvent: Date.now() });
      };
      onTogglex = () => {
        this.setState({ toggleEventx: Date.now() });
      };

    handleChange(event){
        this.setState({
            [event.target.name] : event.target.value
        });
    }
	
	handleSearchChange(text){
		this.setState({
		keyword : text
		});
	}

    handleSubmit(event){
        if(this.state.keyword === '' )
            return   
        
            // console.log(this.state);
        // this.props.history.push('/search/' + this.state.keyword);
		
        this.setState({toSearch : true});
    }


    componentDidMount(){
        var self = this;
        checkTokenServer( function(response){
            self.setState({ isLoggedIn : response });
        });

        document.title="Monitoring " + this.state.client.subdomain_nama ;
        this.getDataCity();
    }


  render(){

    if(!this.state.isLoggedIn)
        return <Redirect to="/login" />

    if (this.state.toSearch === true){
        this.setState({ toSearch:false });
        return <Redirect to={'/search/' + this.state.keyword} />

    } 
    
    return (
        <header className="header-area">
            <div className="top-header-area" 
            style={{backgroundColor:this.state.client.subdomain_color}}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="top-header-content d-flex align-items-center justify-content-between" 
                            style={{backgroundColor:this.state.client.subdomain_color}}
                            >
                                <div className="logo">
                                    <div className='logo-image'>
                                        
                                    </div>
                                    <div className="logo-text">
                                        <img width={'300px'} src={require('../_assets/img/logo.png')} style={{marginBottom:30}}></img>
                                    </div>
                                </div>
                                
                                <div className="login-search-area d-flex align-items-center">
                                    <div className="search-form hide-print">
                                        <form>
											<input type="search" name="keyword" className="form-control" placeholder="Search" onChange={this.handleChange}/>
                                            <button type="button" onClick={this.handleSubmit}><i className="fa fa-search" aria-hidden="true"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="newspaper-main-menu hide-print mobile-hide" id="stickyMenu">
                <div className="classy-nav-container breakpoint-off">
                    <div className="container">
                        <nav className="classy-navbar justify-content-between" id="newspaperNav">           
                            <div className="logo">
                                <Link to={'/'}>
                                    <img src={""} alt="" />
                                </Link>
                            </div>
                            <div className="classy-navbar-toggler">
                                <span className="navbarToggler"><span></span><span></span><span></span></span>
                            </div>
                            <div className="classy-menu">
                                <div className="classycloseIcon">
                                    <div className="cross-wrap"><span className="top"></span><span className="bottom"></span></div>
                                </div>
                                <div className="classynav">
                                    <ul>
                                        <li className="active"><Link to={'/home'}><FontAwesomeIcon icon={faHome} />  Home</Link></li>
                                        <li> <Link to={'/index'}><FontAwesomeIcon icon={faList} /> Index</Link></li>
                                        <li><Link to={'/report'}><FontAwesomeIcon icon={faCheck} /> Laporan</Link></li>
                                        <li><Link to={'/quantitative'}><FontAwesomeIcon icon={faChartLine} />  Analisis</Link>
                                            {/*<ul className="dropdown">
                                                <li><Link to={'/quantitative'}> <FontAwesomeIcon icon={faChartLine} /> Kuantitatif</Link></li>
                                                <li><Link to={'/qualitative/daily'}> <FontAwesomeIcon icon={faFilePdf} /> Analisis Harian</Link></li>
                                                <li><Link to={'/qualitative/weekly'}> <FontAwesomeIcon icon={faFilePdf} /> Analisis Mingguan</Link></li>
                                                <li><Link to={'/qualitative/monthly'}> <FontAwesomeIcon icon={faFilePdf} /> Analisis Bulanan</Link></li>
                                                <li><Link to={'/qualitative/quarterly'}> <FontAwesomeIcon icon={faFilePdf} /> Analisis 3 Bulanan</Link></li>
                                                <li><Link to={'/qualitative/yearly'}> <FontAwesomeIcon icon={faFilePdf} /> Analisis Tahunan</Link></li>
                                            </ul>*/}
                                        </li>
                                        {/*<li><Link to={'/analysis'}><FontAwesomeIcon icon={faChartLine} /> Laporan Analysis</Link></li>
                                        <li><Link to={'/kliping'}><FontAwesomeIcon icon={faFilePdf} /> Kliping</Link></li>*/}
                                        
                                        {/* <li><Link to={'/medsos/report'}><FontAwesomeIcon icon={faCommentAlt} />  Medsos</Link>
                                            <ul className="dropdown">
                                                <li><Link to={'/medsos/report'}> <FontAwesomeIcon icon={faChartLine} /> Laporan</Link></li>
                                                <li><Link to={'/medsos/daily'}> <FontAwesomeIcon icon={faCommentAlt} /> Medsos Harian</Link></li>
                                                <li><Link to={'/medsos/weekly'}> <FontAwesomeIcon icon={faCommentAlt} /> Medsos Mingguan</Link></li>
                                                <li><Link to={'/medsos/monthly'}> <FontAwesomeIcon icon={faCommentAlt} /> Medsos Bulanan</Link></li>
                                                <li><Link to={'/medsos/yearly'}> <FontAwesomeIcon icon={faCommentAlt} /> Medsos Tahunan</Link></li>
                                            </ul>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                            <div >
                                <Link style={{color: '#828282'}} to={'/logout'}><FontAwesomeIcon icon={faSignOutAlt} /> Logout</Link>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            
            <div className='bdz-button-menu-mobile' onClick={this.onToggle}>
                <FontAwesomeIcon icon={faBars} style={{width:30, height:30}}></FontAwesomeIcon>
            </div>

            <SlideToggle
             collapsed
             whenReversedUseBackwardEase
            //  easeCollapse={eases["circOut"]}
            //  easeExpand={eases["circOut"]}
             toggleEvent={this.state.toggleEvent}>
                {({ setCollapsibleElement }) => (
                     <div className="my-collapsible">
                     <div
                        className="my-collapsible__content"
                        ref={setCollapsibleElement}
                        >
                       <div className="my-collapsible__content-inner">

                                <div className="bdz-menu-mobile-wrapper">
                                    <div className="bdz-menu-mobile-container">
                                        <div className="bdz-menu-mobile-item">
                                            <Link to={'/'}>
                                                <FontAwesomeIcon style={{marginRight:'20px'}} icon={faHome}>  </FontAwesomeIcon>
                                                Home
                                            </Link>
                                        </div>
                                        <div className="bdz-menu-mobile-item">
                                            <Link to={'/index'}>
                                                <FontAwesomeIcon style={{marginRight:'20px'}} icon={faList}></FontAwesomeIcon>
                                                Index
                                            </Link>
                                        </div>
                                        <div className="bdz-menu-mobile-item">
                                            <Link to={'/report'}>
                                                <FontAwesomeIcon style={{marginRight:'20px'}} icon={faChalkboard}></FontAwesomeIcon>
                                                Report
                                            </Link>
                                        </div>
                                        <div className="bdz-menu-mobile-item" onClick={this.onTogglex}>
                                           <FontAwesomeIcon style={{marginRight:'20px'}} icon={faChartPie}></FontAwesomeIcon>
                                           Analisis
                                        </div>



                                        <SlideToggle
                                            collapsed
                                            whenReversedUseBackwardEase
                                            //  easeCollapse={eases["circOut"]}
                                            //  easeExpand={eases["circOut"]}
                                            toggleEvent={this.state.toggleEventx}>
                                                {({ setCollapsibleElement }) => (
                                                    <div className="my-collapsible">
                                                    <div
                                                        className="my-collapsible__content"
                                                        ref={setCollapsibleElement}
                                                        >
                                                    <div className="my-collapsible__content-inner">


                                                
                                                                <div className="bdz-menu-mobile-wrapper">
                                                                    <div className="bdz-menu-mobile-container">
                                                                        <div className="bdz-menu-mobile-item">
                                                                            <Link to={'/qualitative/daily'}>Analisis Harian</Link>
                                                                        </div>
                                                                        <div className="bdz-menu-mobile-item">
                                                                            <Link to={'/qualitative/weekly'}>Analisis Mingguan</Link>
                                                                        </div>
                                                                        <div className="bdz-menu-mobile-item">
                                                                            <Link to={'/qualitative/monthly'}>Analisis Bulanan</Link>
                                                                        </div>
                                                                        <div className="bdz-menu-mobile-item">
                                                                            <Link to={'/qualitative/quarterly'}>Analisis 3 Bulanan</Link>
                                                                        </div>
                                                                        <div className="bdz-menu-mobile-item">
                                                                            <Link to={'/qualitative/yearly'}>Analisis Tahunan</Link>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                )}
                                                </SlideToggle>

                                                <div className="bdz-menu-mobile-item">
                                            <Link to={'/analysis'}>
                                                <FontAwesomeIcon style={{marginRight:'20px'}} icon={faChartLine}></FontAwesomeIcon>
                                                Laporan Analisis
                                            </Link>
                                        </div>

                                        <div className="bdz-menu-mobile-item">
                                            <Link to={'/kliping'}>
                                                <FontAwesomeIcon style={{marginRight:'20px'}} icon={faFilePdf}></FontAwesomeIcon>
                                                Kliping
                                            </Link>
                                        </div>

                                    </div>
                                </div>


                                        <div className="search-mobile">
                                        <div className="search-form hide-print">
                                            <form>
                                            <Autocomplete name="keyword" className="form-control" placeholder="Search" onChange={this.handleSearchChange}
                                                        suggestions={this.state.city}
                                                    />
                                                {/* <input type="search" name="keyword" className="form-control" placeholder="Search" onChange={this.handleChange}/> */}
                                                <button type="button" onClick={this.handleSubmit}><i className="fa fa-search" aria-hidden="true"></i></button>
                                            </form>
                                        </div>
                                    </div>                
                        </div>
                    </div>
                </div>
                )}
                </SlideToggle>
               
                
        </header>
        )
  }
 
}

