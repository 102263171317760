import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import ReactAudioPlayer from 'react-audio-player';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
// import Logo from '../../_assets/img/core-img/logo.png';

import { API, CLIENT } from '../../_helpers/Constants';

export default class Login extends Component {
  constructor(props){
    super(props);

    this.state = {
      username : "",
      password : "",
      isLoading : false,
      isLoggedIn : false,
      token : localStorage.getItem("_token"),
      playing: false 
    }
  
    this.handleSumbit = this.handleSumbit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleOnReady = () => setTimeout(() => this.setState({ playing: true }), 100);


  componentDidMount(){
    
    document.title="Monitoring Login"  ;
    if(this.state.token != null)
     this.setState({isLoggedIn : true}); 

  }

  handleChange(event){
    this.setState({
      [event.target.name] : event.target.value
    });
  }

  handleSumbit(event){

    if(this.state.username === '' || this.state.password === '')
      return

    if(this.state.isLoading)
      return

    var self = this;
    this.setState({ isLoading : true });
    var urls =  API + "token/request?username="+ this.state.username +"&password="+ this.state.password +"&subdomain_id=" + CLIENT;
    axios({
      method: 'get',
        url: urls,
    }).then(function (response) {
        if(response.data.status === 200){
          var token = response.data.data.token;

          localStorage.setItem("_token",token);
          localStorage.setItem("_username",self.state.username);
          localStorage.setItem("_profile",JSON.stringify(response.data.data.subdomain));

          self.setState({ isLoggedIn : true });
          
        }else{
          alert(response.data.message);
        }
        self.setState({ isLoading : false });
          
          console.log(response);
    }).catch(function (error) {
          self.setState({ isLoading : false });
          console.log(error);
          alert("Login Failed, Please check your connection..");
    });
  }
 
  render(){
    
    if(this.state.isLoggedIn)
      return <Redirect to="/home"></Redirect>

    
    return (
      <main className="login-form">
        <div className="cotainer">
            <div className="center-form">
                <div className="col-md-12">
                    <div className="card">
                      
                        <div className="card-body">
                        {/*<img width={'300px'} src={require('../../_assets/img/logo.png')} style={{marginBottom:30}}></img>*/}
                          
                          {/* <h1>Login | <small>Monitoring</small></h1> */}
                            {/* <hr></hr> */}
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <input type="text" 
                                                id="username" 
                                                className="form-control" 
                                                name="username"
                                                placeholder='Username'
                                                value={this.state.username}
                                                onChange={this.handleChange} 
                                                required autoFocus />
                                    </div>
                                </div>
  
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <input type="password" 
                                            id="password" 
                                            className="form-control" 
                                                placeholder='Password'
                                                onChange={this.handleChange}
                                            name="password" required />
                                    </div>
                                </div>
  
  
                                <div className="col-md-12 ">
                                    <div style={{float:"right"}}>
                                      <button type="submit" 
                                          className="btn btn-success"
                                          onClick={this.handleSumbit}>
                                        {this.state.isLoading ? <FontAwesomeIcon style={{marginRight:5}} icon={faSpinner} spin/> : '' }
                                          Login
                                      </button>
                                    </div>
                                    
                                </div>
                      
                    </div>

   

                    {/* <ReactAudioPlayer
                      src={require('../../_assets/mars.ogg')}
                      autoPlay={true}
                      controls={false}
                      loop={true}
                    /> */}
                </div>
              </div>
          </div>
        </div>
      </main>
  
  
    )
  }
 
}

