import React,{ Component } from 'react';
import { DateHelper } from '../../_helpers/DateHelper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner,faFilePdf  } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';
import { API, CLIENT, ASSETS_URL } from '../../_helpers/Constants';

import  Header from '../../_components/Header';
import  Footer from '../../_components/Footer';

export default class Medsos extends Component {

    constructor(props){
        super(props);
        this.state = {
            id : '',
            result : [],
            type : 1,
            isLoading : false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }


    componentDidMount(){
        const type = this.props.match.params.type 
        var numberType = 1;   
        
        if(type === 'daily'){
            numberType = 1;
            
        }else if(type  ===  'weekly'){
            numberType = 2;
        }else if(type  ===  'monthly'){
            numberType = 3;
        }else if(type === 'quarterly'){
            numberType = 4;
        }else if(type === 'yearly'){
            numberType = 5;
        }else{
            numberType = 1;
        }

        this.setState({ type : numberType });
        this.getDataResult(numberType);
    }
    componentWillReceiveProps(nextProps){
        this.setState({result : []});
        const type = nextProps.match.params.type  ;   
        var numberType = 1;

        if(type === 'daily'){
            numberType = 1;
        }else if(type === 'weekly'){
            numberType = 2;
        }else if(type === 'monthly'){
            numberType = 3;
        }else if(type === 'quarterly'){
            numberType = 4;
        }else if(type === 'yearly'){
            numberType = 5;
        }else{
            numberType = 7;
        }

        this.setState({ type : numberType });
        this.getDataResult(numberType);
    }

    handleChange(event){
        this.setState({
            [event.target.name] : event.target.value
        });
    }

    handleSubmit(event){
        this.getDataResult(this.state.type);
    }

    getDataResult(type){
        this.setState({isLoading : true});
        var self = this;
        var urls =  API + "analysis/getmedsos?username="+ localStorage.getItem('_username')
            + "&token="+ localStorage.getItem('_token') 
            + "&subdomain_id=" + CLIENT 
            + "&limit=5" 
            + "&type=" + type
        console.log(urls);
        axios({
            method: 'get',
            url: urls,
        }).then(function (response) {
            if(response.data.status  ===  200){
                console.log(response.data.data);
                self.setState({ result : response.data.data});
            }
            self.setState({isLoading : false});
        }).catch(function (error) {
            self.setState({isLoading : false});
        });
    }

    
     
    render(){        
        return (

            <div>
                <Header></Header>

                <br></br>
                    <div className="blog-area section-padding-0-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="contact-title" style={{float:'left'}}>
                                    <h2>Medsos Report</h2>
                                    <small>
                                    Medsos {this.state.type  ===  1 ? 'Harian' : this.state.type  ===  2 ? 'Mingguan' : this.state.type  ===  3 ? 'Bulanan' : this.state.type  ===  4 ? '3 Bulanan' : this.state.type == 5 ? 'Tahunan' : 'Report'}
                                    </small>
                                </div>

                            </div>
                        </div>
                        <div className='clearfix'></div>
                        <br></br>
                        <div className="row">
                            <div className='col-12 col-md-12'>
                            {this.state.isLoading ? <FontAwesomeIcon style={{marginRight:5}} icon={faSpinner} spin/> : '' }
                                
                            <ul className='bdz-list-recomendation'>
                            { this.state.result.map((item, i) => {
                                          return <li>
                                              <a href={ASSETS_URL + '/img/medsos/' + item.medsos_file} target="_blank"><FontAwesomeIcon size="2x" icon={faFilePdf} />  medsos {this.state.type  ===  1 ? 'Harian' : this.state.type  ===  2 ? 'Mingguan' : this.state.type  ===  3 ? 'Bulanan' : this.state.type  ===  4 ? '3 Bulanan' : this.state.type == 5 ? 'Tahunan' : 'Report'}, <span className='date'>Tanggal {item.medsos_waktu}</span></a>
                                          </li>          
                                        })
                                    } 
                            </ul>
                            </div>
                            
                        </div>
                    </div>
                </div>


                <Footer></Footer>
            </div>
        ) 
    }
  
}

