import React,{ Component } from 'react';

import  Header from '../../_components/Header';
import  Footer from '../../_components/Footer';
import  Vertical_1 from '../../_components/Vertical_1';
import  Related_1 from '../../_components/Related_1';



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faPrint } from '@fortawesome/free-solid-svg-icons';

import ReactToPrint from 'react-to-print';


import axios from 'axios';
import { API, CLIENT, ASSETS_URL } from '../../_helpers/Constants';
import { Link } from 'react-router-dom';
import Original from '../Print/Original';

export default class News extends Component {
    constructor(props){
        super(props);
        this.state = {
            id : this.props.match.params.id   ,
            data : []
        }
      
    }
    ImageHelper(ev){
        var client = JSON.parse(localStorage.getItem('_profile'));
        ev.target.src = ASSETS_URL + "img/logo/" + client.subdomain_logo
      }
    
    decode(str) {
        return str.replace(/&#(\d+);/g, function(match, dec) {
            return String.fromCharCode(dec);
        });
    }

  componentDidMount() {
    const idx = this.props.match.params.id    
    this.setState({id : idx});
    this.getDataNews(idx);
  }


  getDataNews(id){
    var self = this;
    var urls =  API + "news/getdetail?username="+ localStorage.getItem('_username')
      + "&token="+ localStorage.getItem('_token') 
      + "&subdomain_id=" + CLIENT
      + "&id="+ id
      + "&date_from=2017-01-01&date_to=2025-12-31";

//   console.log(urls);
  axios({
      method: 'get',
        url: urls,
    }).then(function (response) {
        if(response.data.status == 200){
            // console.log(response.data.data);
          self.setState({ data : response.data.data[0]});
        }else{
          alert(response.data.message);
        }
    }).catch(function (error) {
         
    });
}

    componentWillReceiveProps(nextProps){
        const idx = nextProps.match.params.id  ;   
        this.getDataNews(idx);
    }


     
    render(){        
 

        return (

            <div  ref={el => (this.componentRef = el)}>
                <Header></Header>

            <br></br>
                <div className="blog-area section-padding-0-80">
        <div className="container">
            <div className="row">
                <div className="col-xs-9 col-sm-9 col-lg-9">
                    <div className="blog-posts-area">

                        <div className="single-blog-post featured-post single-post">
                            
                            <div className='row bdz-print-news-container'>
                                <div className='bdz-sm-30 bdz-md-30'>
                                    <div className="post-thumb">
                                        <a href="#">
                                            <img onError={this.ImageHelper} src={ASSETS_URL + '/img/photo/' + this.state.data.berita_foto} alt=""/>
                                            </a>
                                    </div>
                                    <div className="post-detail-data">
                                     <div className='title'>News Detail</div>
                                        
                                    <div className='label'>TANGGAL :</div>
                                    <div className='value'> {this.state.data.berita_tanggal_tampil}</div>

                                    <div className='label'>JAM NAIK BERITA :</div>
                                    <div className='value'>{this.state.data.berita_jam_tampil}</div> 

                                    <div className='label'>MEDIA :</div>
                                    <div className='value'>{this.state.data.media_nama}</div> 

                                    <div className='label'>WARTAWAN :</div>
                                    <div className='value'>{this.state.data.wartawan_nama}</div> 

                                    <div className='label'>NARASUMBER :</div>
                                    <div className='value'>{this.state.data.narasumber_nama}</div> 

                                    <div className='label'>NILAI BERITA :</div>
                                    <div className='value'>{this.state.data.berita_tone == 1 ? 'Positif' : (this.state.data.berita_tone == 0 ? 'Netral' : 'Negatif')}</div>   

                                    <div className='label'>TEMA :</div>
                                    <div className='value'>{this.state.data.topik_nama}</div>  

                                    <div className='label'>SUB TEMA :</div>
                                    <div className='value'>{this.state.data.subtopik_nama}</div>  

                                    <div className='label'>INSTITUSI :</div>
                                    <div className='value'>{this.state.data.lembaga_nama}</div> 

                                    <div className='label'>RUBRIK :</div>
                                    <div className='value'>{this.state.data.rubrikasi_nama}</div> 

                                    { this.state.data.berita_jenis == 1 ? 
                                    <div> 
                                        <div className='label'>LINK ORIGINAL </div>
                                        <div className='value'><a href={this.state.data.berita_link_org} target="_blank">Link</a></div>
                                    </div>
                                    : ''}

                                    
                                        {/* <ReactToPrint
                                            trigger={() => <a href="#" className="btn float-left  btn-info btn-sm my-1 mr-sm-2 hide-print">
                                            <FontAwesomeIcon style={{marginRight:5}} icon={faPrint}/> Print
                                            </a>}
                                            content={() => this.componentRef}
                                        
                                    /> */}

                                    <ReactToPrint
                                            trigger={() => <a href="#" className="btn float-left  btn-warning btn-sm my-1 mr-sm-2 hide-print">
                                            <FontAwesomeIcon style={{marginRight:5}} icon={faPrint}/> Print Original
                                            </a>}
                                            content={() => this.printOri}
                                        
                                    />

                                    <ReactToPrint
                                            trigger={() => <a href="#" className="btn float-left  btn-info btn-sm my-1 mr-sm-2 hide-print">
                                            <FontAwesomeIcon style={{marginRight:5}} icon={faPrint}/> Print Berita
                                            </a>}
                                            content={() => this.printNews}
                                        
                                    />

                                      

                                        {/* <Link to={"/print/original/"+this.state.id} target="_blank">
                                            <FontAwesomeIcon style={{marginRight:5}} icon={faPrint}/> Print
                                        </Link> */}
                                   
                                      

                                    </div>
                                </div>
                                <div className='bdz-sm-70 bdz-md-70'>
                                    <div className="post-data">
                                        <a href="#" className="post-title">
                                            <h6>{this.state.data.berita_judul}</h6>
                                        </a>
                                        <div className="post-meta">
                                            <p><i>{this.state.data.berita_resume}</i></p>
                                            <div>
                                                {
                                                    this.state.data.berita_link !='' ?
                                                    
                                                        <iframe style={{
                                                            alignSelf: 'contain',
                                                            height: 400,
                                                            width: '100%',
                                                        }} src={this.state.data.berita_link}></iframe>
                                                    : 
                                                <div dangerouslySetInnerHTML={ { __html : this.state.data.berita_isi } } /> 

                                                    
                                                }
                                                
                                               
                                            </div>
                                        </div>

                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.data.berita_jenis == 3 ?
                                <div className="break-print">
                                    <br />
                                    <b>File Original :</b>
                                    <br />
                                    <br />
                                    <img onError={this.ImageHelper} src={ASSETS_URL + '/img/file_original/' + this.state.data.berita_file} alt=""/>
                                </div>
                            :null
                        }
                        <div className='hide-print'>
                            <Related_1 total="8" category={this.state.data.topik_id} type='printed'></Related_1>
                        </div>

                    </div>
                </div>

                <div className="col-xs-3 col-sm-3 col-lg-3 hide-print">
                    <div className="blog-sidebar-area">
                        <div className="latest-posts-widget mb-50">
                            <Vertical_1 total="5" showBig1="false" type="online" category=""></Vertical_1>
                            <br></br>
                            <Vertical_1 total="5" showBig1="false" type="Printed" category=""></Vertical_1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



                <div style={{display:'none'}}>
                     <Original ref={elx => (this.printOri = elx)} id={this.state.id} data={this.state.data} isOri={true}/>
                </div>

                <div style={{display:'none'}}>
                     <Original ref={elx => (this.printNews = elx)} id={this.state.id} data={this.state.data} isOri={false}/>
                </div>

                <Footer></Footer>
            </div>
        ) 
    }
  
}

